import React from "react"
import { Field } from "react-final-form"
import styled from "styled-components"
import { Content } from "./section-styling"

//The following components are imported into pages/contact.js to build our contact form.

//Style the container for our form questions
export const FormContent = styled(Content)`
  flex-flow: column nowrap;

  .error {
    width: 145px;
    font-size: 0.7em;
    font-family: Robot, sans-serif;
    font-weight: bold;
    color: red;
    position: absolute;
    left: 260px;
  }
`

//Style our form questions
export const QuestionsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 1.2em;
  margin-bottom: 15px;

  .field {
    border: 2px solid var(--light-green);
    padding: 5px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 250px;
  }

  .field:focus {
    border: 2px solid red;
  }

  .choice {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    padding: 5px;

    input {
      margin-right: 8px;
    }

    input:focus {
      outline: 1px solid red;
    }
  }
`

//Style the label for each question field
export const Question = styled.label`
  color: var(--white);
  font-size: 1em;
  font-family: Caladea, serif;
  font-weight: bold;
  position: relative;
`

//Define the options for our checkboxes here, to be imported into our form
export const representingOptions = {
  individual: "an individual",
  organization: "an organization",
  business: "a business",
}
export const serviceOptions = {
  accessibility_research: "Accessibility Research",
  consulting: "Consulting",
  custom_software: "Custom Software",
  graphic_design: "Graphic Design",
  hosting: "Hosting and Maintenance",
  mobile: "iOS, Android, and Hybrid Devices",
  interaction_design: "Interaction Design",
  tech_architecture: "Tech Architecture Strategy",
  social_computing_research: "Social Computing Research",
  cms_customization:
    "Wordpress, Drupal, Civi CRM CUstomization, Jekyll, React Static, Custom Plugins",
  other: "Other",
}
export const projectOptions = {
  big_idea: "A big idea!",
  outline: "Has an outline",
  budget: "Has a budget",
  timeline: "Has a timeline",
  stakeholders: "Has assigned stakeholders",
}

/* Define reusable component for the checkbox questions */
export const CheckboxGroup = ({ fields, options }) => {
  const toggle = (event, option) => {
    if (event.target.checked) fields.push(option)
    else fields.remove(option)
  }

  const isChecked = option => {
    return fields.value && fields.value.includes(option) ? true : false
  }

  const keys = Object.getOwnPropertyNames(options)

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        color: "var(--white)",
      }}
    >
      {keys.map(key => (
        <label key={key} className="choice" htmlFor={key}>
          <input
            type="checkbox"
            id={key}
            name={key}
            onClick={event => toggle(event, key)}
            defaultChecked={isChecked(key)}
          />
          {options[key]}
        </label>
      ))}
    </div>
  )
}

/* Define errors and how they show up in the form */
export const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span className="error">{error}</span> : null
    }
  />
)
export const required = value => (value ? undefined : "*required")
export const atLeastOne = value =>
  value && value.length > 0 ? undefined : "*required"
export const validEmail = value => {
  const validEmailExpression =
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

  if (value === undefined) {
    return "*required"
  }

  if (value && !validEmailExpression.test(String(value).toLowerCase())) {
    return "*please enter a valid email"
  }
}
