import React from "react"
import { navigate } from "gatsby"
import { ButtonLink } from "../components/ButtonLink"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import Dropzone from "../components/Dropzone"

import Wizard from "../components/Wizard"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  WrapperLight,
  WrapperDarkGreen,
  Banner,
} from "../components/section-styling"
import {
  FormContent,
  Question,
  QuestionsList,
  representingOptions,
  serviceOptions,
  projectOptions,
  CheckboxGroup,
  Error,
  required,
  atLeastOne,
  validEmail,
} from "../components/form-helpers"

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const onSubmit = async values => {
  await sleep(300)
  const formData = new FormData()

  for (const property in values) {
    if (property === "files") {
      const files = values[property]
      files.forEach(file => {
        // Append all attachments to the formData object. The second set of square brackets [] are needed so that all files are correctly appended, as per https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
        formData.append(`business_inquiry[files][]`, file)
      })
    } else {
      formData.append(`business_inquiry[${property}]`, values[property])
    }
  }

  fetch("https://backstage.sassafras.coop/business_inquiries", {
    method: "POST",
    body: formData,
  })
    .then(response => {
      console.log("Success: ", response.ok)
      navigate("/confirmation", {
        replace: true,
        state: { formSuccess: true },
      })
    })
    .catch(error => {
      console.error("Error:", error)
      navigate("/confirmation", {
        replace: true,
        state: { formSuccess: false },
      })
    })
}

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Get Started" />

      <WrapperDarkGreen>
        <FormContent>
          {/* TODO fix Warning: A form label must be associated with a control.
              Must set "Field" custom component as a control
          */}
          <Wizard onSubmit={onSubmit}>
            {/* Encase in a flexbox so that the image appears to the right */}
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
              }}
            >
              <Wizard.Page>
                {" "}
                {/* Page 1 */}
                <h1>Hey there!</h1>
                <p>
                  We're Sassafras, a worker-owned tech cooperative. We
                  specialize in web apps and web design with social change in
                  mind. Now that you know who we are, we want to learn a bit
                  about you. Please fill out the form below. Or, if you prefer,
                  you can email us directly: <b>info</b> [at] <b>sassafras</b>{" "}
                  [dot] <b>coop</b>.
                </p>
                <QuestionsList>
                  <Question>
                    My name is...
                    <br />
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="your name"
                      className="field"
                      validate={required}
                    />
                    <Error name="name" />
                  </Question>

                  <Question>
                    My pronouns are...
                    <br />
                    <Field
                      name="pronouns"
                      component="input"
                      type="text"
                      placeholder="e.g., she/her, they/them, he/him"
                      className="field"
                    />
                  </Question>

                  <Question>
                    My email is...
                    <br />
                    <Field
                      name="email"
                      component="input"
                      type="text"
                      placeholder="hello@example.com"
                      className="field"
                      validate={validEmail}
                    />
                    <Error name="email" />
                  </Question>

                  <Question>
                    I'm representing a/an...
                    <br />
                    <small>(check as many as you like)</small>
                    <Error name="representing" />
                  </Question>
                  <FieldArray
                    name="representing"
                    component={CheckboxGroup}
                    options={representingOptions}
                    validate={atLeastOne}
                  />
                </QuestionsList>
              </Wizard.Page>

              <StaticImage
                src="../images/sassy-plant-16.png"
                layout="constrained"
                className="large-image"
                width={350}
                alt=""
              />
            </div>

            <Wizard.Page>
              {" "}
              {/* Page 2 */}
              <div>
                <h2>Nice to meet you!</h2>

                <QuestionsList>
                  <Question>
                    So tell us what brings you here today.
                    <br />
                    <Field
                      name="here_because"
                      component="textarea"
                      placeholder="I am here because..."
                      className="field"
                      validate={required}
                      ref={input => input && input.focus()}
                    />
                    <Error name="here_because" />
                    <br />
                    <Field
                      name="heard_from"
                      component="textarea"
                      className="field"
                      placeholder="I heard about you all from..."
                    />
                  </Question>

                  <Question>
                    I want a/an...
                    <br />
                    <Field
                      name="product_desired"
                      component="select"
                      validate={required}
                      className="field"
                    >
                      <option value={undefined}></option>
                      <option value="web_app">Web App</option>
                      <option value="mobile_app">Mobile App</option>
                      <option value="website">Website</option>
                      <option value="tool">Tool</option>
                      <option value="strategy">Strategy</option>
                      <option value="research">Research</option>
                      <option value="web_design">Web Design</option>
                    </Field>
                    <Error name="product_desired" />
                  </Question>

                  <Question>
                    I need help with...
                    <br />
                    <small>(check as many as you need)</small>
                    <Error name="service" />
                  </Question>
                  <FieldArray
                    name="service"
                    component={CheckboxGroup}
                    options={serviceOptions}
                    validate={atLeastOne}
                  />
                </QuestionsList>
              </div>
            </Wizard.Page>

            <Wizard.Page>
              {" "}
              {/* Page 3 */}
              <div>
                <h2>Almost done! Just a few more details...</h2>

                <QuestionsList>
                  <Question>
                    My project has been around for...
                    <br />
                    <Field
                      name="project_history"
                      component="select"
                      validate={required}
                      className="field"
                      ref={input => input && input.focus()}
                    >
                      <option value={undefined}></option>
                      <option value="brand_new">It's brand new</option>
                      <option value="less-than-two-yrs">
                        Less than 2 years
                      </option>
                      <option value="three_to_five_years">3 to 5 years</option>
                      <option value="six_to_nine_years">6 to 9 years</option>
                      <option value="ten_plus_years">10 or more years</option>
                    </Field>
                    <Error name="project_history" />
                  </Question>

                  <Question>
                    My project is...
                    <br />
                    <small>(check all that apply)</small>
                    <Error name="project_details" />
                  </Question>
                  <FieldArray
                    name="project_details"
                    component={CheckboxGroup}
                    options={projectOptions}
                    validate={atLeastOne}
                  />

                  <Question>
                    My deadline is in...
                    <br />
                    <Field
                      name="project_deadline"
                      component="select"
                      validate={required}
                      className="field"
                    >
                      <option value={undefined}></option>
                      <option value="not_sure">Not sure</option>
                      <option value="a_few_weeks">A few weeks</option>
                      <option value="a_few_months">A few months</option>
                      <option value="four_to_six_months">4 to 6 months</option>
                      <option value="seven_to_eight_months">
                        7 to 8 months
                      </option>
                      <option value="nine_plus_months">
                        9 months to a year
                      </option>
                      <option value="over_a_year">Over a year</option>
                      <option value="flexible">Flexible</option>
                    </Field>
                    <Error name="project_deadline" />
                  </Question>

                  <Question>
                    My budget is...
                    <br />
                    <Field
                      name="project_budget"
                      component="select"
                      validate={required}
                      className="field"
                    >
                      <option value={undefined}></option>
                      <option value="nothing_yet">Nothing yet</option>
                      <option value="1k_to_5k">1K - 5K USD</option>
                      <option value="5k_to_15k">5K - 15K USD</option>
                      <option value="15k_to_25k">15K - 25K USD</option>
                      <option value="25k_to_50k">25K - 50K USD</option>
                      <option value="50k_to_70k">50K - 70K USD</option>
                      <option value="70k_to_100k">70K - 100K USD</option>
                    </Field>
                    <Error name="project_budget" />
                  </Question>

                  <Question>
                    Anything we missed? Add any extra details or files here!
                    <br />
                    <Field
                      name="anything_else"
                      component="textarea"
                      className="field"
                      placeholder="Enter text here..."
                    />
                  </Question>

                  <Question>
                    <Field name="files">
                      {props => (
                        <div>
                          <Dropzone {...props.input} />
                        </div>
                      )}
                    </Field>
                  </Question>

                  <h3>All done? Send it in!</h3>
                </QuestionsList>
              </div>
            </Wizard.Page>
          </Wizard>{" "}
          {/* Form ends here */}
        </FormContent>
      </WrapperDarkGreen>

      <WrapperLight>
        <Banner>
          <h2>Wondering what we do?</h2>
          <ButtonLink to="/services" className="light long">
            VIEW OUR SERVICES
          </ButtonLink>
        </Banner>
      </WrapperLight>
    </Layout>
  )
}

export default ContactPage
