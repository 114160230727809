import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

// Adapted from: https://codesandbox.io/s/gracious-star-xcbzk?fontsize=14&file=/src/Dropzone.js

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676"
  }
  if (props.isDragReject) {
    return "#ff1744"
  }
  if (props.isFocused) {
    return "red"
  }
  return "var(--black)"
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border-width: 4px;
  border-radius: 5px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: var(--white);
  color: var(--black);
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  .innerText {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 1em;
    color: var(--dark-green);
    margin: 0 auto;

    &:hover {
      color: var(--teal);
      transition: color .24s ease-in-out;
    }
  }

  .listContainer {
    display: "flex",
    justify-content: "flex-end",
    flexDirection: "column",
    flexWrap: "nowrap",
    margin: 20px; 
  }
`

const fileDisplay = {
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: ".9em",
  boxSizing: "border-box",
  margin: "20px",
  fontFamily: "Roboto, sans-serif",
  fontWeight: "normal",
}

const removeFileButton = {
  backgroundColor: "#ffbab5",
  flexShrink: 0,
}

function Dropzone(props) {
  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple: true,
      onDrop: acceptedFiles => {
        const files = acceptedFiles
        setFiles(files)
        if (props.onChange) {
          props.onChange(files)
        }
      },
    })

  const removeFile = file => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  const fileListDisplay = files.map(file => (
    <div style={fileDisplay} key={file.name}>
      <span>{file.name}</span>
      <button onClick={removeFile(file)} style={removeFileButton}>
        REMOVE
      </button>
    </div>
  ))

  return (
    <div>
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p className="innerText">
          Drag and drop some files here, or click to select files
        </p>
      </Container>
      {fileListDisplay}
    </div>
  )
}

export default Dropzone
